import {useState} from "react"

function Chinese(props){
    return (
        <div>
            <p style={{'font-size': 'x-large'}}>{props.chinese}</p>
        </div>
    )
}

export default Chinese;