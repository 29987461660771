import {useEffect, useState} from "react"


function English1(props){
    const [words, setWords] = useState([])
    useEffect(() => {
        if (props.mode === 'hide'){
            setWords(props.english.map((item)=>{
                if ((Math.random() < 0.8) && item !== ',' && item !== '.' && item !== '!' && item !== '?'){
                    return <><span style={{'font-size': 'x-large', 'color':'gray', 'background-color': 'gray'}}>{item}</span><span> </span></>
                }
                else{
                    return <><span style={{'font-size': 'x-large'}}>{item}</span><span> </span></>
                } 
            }));
        }
        else{
            setWords(props.english.map((item)=>{
                return <><span style={{'font-size': 'x-large'}}>{item}</span><span> </span></>
            }))
        }
        
    }, [props.english, props.mode])

    return (
        <div>
            {words}
        </div>
    )
}

export default English1;