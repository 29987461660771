import logo from './logo.svg';
import './App.css';
import { useEffect, useState } from 'react';
import English1 from './English1'
import Chinese from './Chinese'

function App() {
  const [chinese, setChinese] = useState('');
  const [english, setEnglish] = useState([]);
  const [mode, setMode] = useState('show');

  useEffect(() => {
    async function fetchData(){
      let response = await fetch('https://xy.yyutt0313.workers.dev/');
      let json_object = await response.json();
      console.log(json_object);
      setChinese(json_object.chinese);
      setEnglish(json_object.english)
    }

    fetchData();
  },[])
  
  return (
    <>
      <English1 english={english} mode={mode}></English1>
      <Chinese chinese={chinese}></Chinese>
      <button onClick={()=>mode === 'hide' ? setMode('show') : setMode('hide')} style={{'font-size': 'x-large'}}>hide</button>
    </>
  );
}

export default App;
